import React from "react"
import { Link } from "gatsby"
import NewsHeader from "../../components/blog/blogHeader"
import NewsFooter from "../../components/blog/blogFooter"
import Footer from "../../components/footer"
import SEO from "../../components/seo"

import arrow from "../../../assets/images/servicesPages/arrow-right-min.png"
import development from "../../../assets/images/blog/development/development-header.jpg"
import development1 from "../../../assets/images/blog/development/development1.png"
import development2 from "../../../assets/images/blog/development/development2.png"
import development3 from "../../../assets/images/blog/development/development3.png"
import development4 from "../../../assets/images/blog/development/development4.png"

export default () => 
<div>
    <SEO title={"ICON Worldwide’s Project Development Model"} 
    description="Over the course of 20 years, we have designed some models that are valuable to project development of all sizes. Discover more on ICON’s blog!"
    canonical={'https://icon-worldwide.com/blog/project-development-model'}
    image={'https://icon-worldwide.com/social_images/development.jpg'} />

    <NewsHeader/>

    <div id="single-news">
            <h1>The ICON Website <span>Development Process</span></h1>
            <div id="title-underline"></div>
            <img src={development} alt="ICON Worldwide’s Website Development Process" title="ICON Worldwide’s Website Development Process" style={{width:'100%', display:'block', margin:'0 0 30px'}}/>
        <div id="single-news-texts">
          <p>In a recent discussion with a then “prospective”, now “new client”, I was pleased to hear him say, “I’m talking to several potential partners, you guys seem very concerned about making sure I understand how you work”.</p>
          <p><strong>We have all heard of, and experienced, projects that fall off a cliff.</strong></p>
          <p>This is often caused by poor planning or miscommunication of expectations. The implication for the client is an increasing timeline and escalating costs. For the agency, this can lead to changing specs in process and endless manhours which are not always fairly compensated.</p>
          <p><strong>Over the course of 20 years and hundreds of website development projects we have developed some models that work and are designed to realise projects of all sizes​</strong> in a way that supports ​<a href="https://www.forbes.com/sites/thesba/2013/04/25/tips-for-building-long-term-client-relationships/#28aaccb75567" target="_blank" rel="noopener noreferrer">long-term client partnerships​</a>.</p>
          <h2>1. Classic Project Planning</h2>
          <p>Most of our corporate clients must stay within a fixed budget, and hold their agencies to fixed deliverables and timelines</p>
          <p><strong>The first phase is Project Definition</strong>​, this defines the full vision and scope of the project in as much detail as possible, including:</p>
          <ul>
              <li><strong>Objectives</strong></li>
              <li><strong>Functionality</strong></li>
              <li><strong>Timeline</strong></li>
              <li><strong>Budget</strong></li>
          </ul>
          <p>Often, we will already set out the goals for the MVP version of the product within the scope of the complete final product.</p>
          <img src={development1} alt="Classic Project Planning" title="ICON, Classic Project Planning" style={{width:'100%', display:'block', margin:'0 0 30px'}}/>
          <h2>2. Good documentation is the cornerstone for all digital projects</h2>
          <p><strong>Project planning is supplemented by a few product specification documents.</strong></p>
          <div className="three-column-ul">
            <ul>
                <p><strong>Phase I:</strong> MVP Definition & Design</p>
                <li>Competitive review</li>
                <li>Best practices summary</li>
                <li>User persona definition</li>
                <li>Information architecture</li>
                <li>Sitemaps</li>
                <li>Wireframes</li>
                <li>User flows</li>
                <li>Design concept</li>
                <li>3 design proposals</li>
            </ul>
            <div className="silver-btn">Full site prototype</div>
          </div>
          <div className="three-column-ul">
            <ul>
                <p><strong>Phase II:</strong> Technical Specifications</p>
                <li>User requirements</li>
                <li>Functional specifications</li>
                <li>Code standards </li>
                <li>CMS definition (if relevant)</li>
                <li>Hosting scenarios</li>
                <li>Security requirements</li>
                <li> Device and operating systems </li>
            </ul>
            <div className="silver-btn">Tecimical specification document </div>
          </div>
          <div className="three-column-ul">
            <ul>
                <p><strong>Phase III:</strong> MVP Development</p>
                <li>Agile development</li>
                <li>Front & Back-end sprints</li>
                <li>Staggered releases </li>
                <li>Q/A testing</li>
                <li>Continuous optimisation</li>
            </ul>
            <div className="silver-btn">MVP Release 1 </div>
          </div>
          <h2>3. Complete website design prototypes, prior to development</h2>
          <p><strong>Depending on the size and complexity of the project we will often pursue <Link to="/services/design/">​design development​</Link> in two phases:</strong></p>
          <h3>Phase 1: Design Concept</h3>
          <p>In this phase we present three unique design directions each consisting of at least 2 fully designed pages.</p>
          <p>Once the client selects a direction, we refine it to the extent necessary to get final approval.</p>
          <p><strong>Note: Until this point, no invoicing has occurred. This is done to ensure that the client has a full understanding of what the <Link to="/services/website-development/">​website</Link>​ will look like when it is done, minimizing any potential insecurity.</strong></p>
          <h3>Phase 2. Comprehensive Pre-Development</h3>
          <img src={development2} alt="Phase 2. Comprehensive Pre-Development" title="ICON,Phase 2. Comprehensive Pre-Development" style={{width:'100%', display:'block', margin:'0 0 30px'}}/>
          <ul className="column-ul">
              <p><strong>Information Architecture</strong></p>
              <li>Site maps</li>
              <li>Wireframes</li>
          </ul>
          <ul className="column-ul">
              <p><strong>Design Styleguide</strong></p>
              <li>Key pages, screen design</li>
              <li>GUI style guide, key elements</li>
          </ul>
          <ul className="column-ul">
              <p><strong>Design Prototyping Tools</strong></p>
              <li>InVision</li>
          </ul>
          <p>We believe in taking the extra time to fully design every single page of the website and present it as a prototype in a tool such as ​<a href="https://www.invisionapp.com/" target="_blank" rel="noopener noreferrer">Invision</a>​.</p>
          <p>The client has the ability to comment and change to the extent necessary. We are also able to perform simple user-testing to confirm basic user flows, such as purchase processes.</p>
          <p><strong>Note: Again, this step is designed to ensure all sides are fully clear how the website will look at work before development begins.</strong></p>
          <h2>4. Client involvement in the development process</h2>
          <p>Once development has begun, we encourage our clients to be involved to the extent they are
            willing. At a minimum we will include the client in a weekly sprint planning session.</p>
          <p>This process has the advantage of ​<strong>keeping client an agency fully aligned with the myriad of tiny and larger decisions which can arise in development​</strong>. Potential issues can be solved collectively, and priorities can shift, with full understanding of the implications.</p>
          <img src={development3} alt="Client involvement in the development process" title="Client involvement in the development process" style={{width:'100%', display:'block', margin:'0 0 30px'}}/>
          <h3>Phased, low-impact, releases</h3>
          <p>We try to encourage our clients to view ​<Link to="/services/software-development/">software development​</Link> on a continuum. After the MVP, we will try to plan a series of smaller releases. This has the advantage:</p>
          <ul>
              <li>Increased speed to MVP release</li>
              <li>Minimise risk of incremental features or content</li>
              <li>Iterative improvements from real use-cases</li>
              <li>Budget transparency on a per release basis</li>
          </ul>
          <h2>5. Service & support post launch</h2>
          <p>After the launch of a website most clients require some level of ongoing development and support. Even simple websites built in ​<a href="https://wordpress.com/" target="_blank" rel="noopener noreferrer">WordPress​</a> or <Link to="/blog/drupal-7-end-of-life/">​Drupal​</Link> will have periodic security updates. Which need to be performed by a professional.</p>
          <p>Many clients, however, also want to be able to continue to ​<strong>expand their website in terms of both content and functionality</strong>​. This expansion is critical to ​<Link to="/services/seo/">SEO</Link>​ as well, as google favors dynamically updated websites.</p>
          <p>We will typically agree on a model which provides some level of <Link to="/blog/why-invest-in-nearshore-software-development/">​dedicated developer time</Link>​. In this retainer the client can be ensured of:</p>
          <ul>
              <li><strong>Fixed x days per month of developer time which can be used for critical security updates</strong></li>
              <li><strong>24/7 on-call support</strong></li>
              <li><strong>30 min., minimum response time for urgent issues</strong></li>
              <li><strong>Help desk, chatbot support. etc.</strong></li>
          </ul>
          <p>We bill this time on a quarterly basis, actualised against time-sheets. Depending on the agreement we usually plan a minimum quarterly release update with some new functionality.</p>
          <p><strong>Example Retainer Staff:</strong></p>
          <img src={development4} alt="Client involvement in the development process" title="Client involvement in the development process" className="table-img"/>
       </div>

       <img src={arrow} id="single-news-horizontal-arrow" alt="ICON Worldwide arrow" title="ICON Worldwide arrow"/>
        <p style={{textAlign:'center'}}><strong>Interested in starting a web development project?</strong></p>
        <h4><Link to="/contact-us" className="cta">BOOK A MEETING NOW</Link></h4>

            <NewsFooter previous="top-ecommerce-best-practices" next="ecommerce-trends-2020"/>
        
    </div>

    <Footer noScrollbar="true"/>
</div>